<template>
  <validation-observer ref="userRules">
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-card title="User Details">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="User Name"
                  label-for="v-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-username"
                      v-model="name"
                      placeholder="Peter Pan"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Business"
                  label-for="v-business"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Business"
                    rules="required"
                  >
                    <b-form-select
                      id="v-business"
                      v-model="business"
                      :options="businesses"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Email"
                  label-for="v-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="v-email"
                      v-model="emailAddress"
                      placeholder="peter@pan.com"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Admin"
                  label-for="v-admin"
                >
                  <b-form-checkbox
                    id="v-admin"
                    v-model="isAdmin"
                    class="custom-control-info"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Enabled"
                  label-for="v-enabled"
                >
                  <b-form-checkbox
                    id="v-enabled"
                    v-model="enabled"
                    class="custom-control-info"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Users"
                  label-for="v-permissions"
                >
                  <b-form-checkbox
                    v-for="option in permissionOptions"
                    :key="option.value"
                    v-model="permUsers"
                    :disabled="admin"
                    :value="option.value"
                    :class="option.class"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Devices"
                  label-for="v-permissions"
                >
                  <b-form-checkbox
                    v-for="option in permissionOptions"
                    :key="option.value"
                    v-model="permDevices"
                    :disabled="admin"
                    :value="option.value"
                    :class="option.class"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Data"
                  label-for="v-permissions"
                >
                  <b-form-checkbox
                    v-for="option in permissionOptions"
                    :key="option.value"
                    v-model="permData"
                    :value="option.value"
                    :class="option.class"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="ApiKey"
                  label-for="v-permissions"
                >
                  <b-form-checkbox
                    v-for="option in permissionOptions"
                    :key="option.value"
                    v-model="permApiKey"
                    :value="option.value"
                    :class="option.class"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Businesses"
                  label-for="v-permissions"
                >
                  <b-form-checkbox
                    v-for="option in permissionOptions"
                    :key="option.value"
                    v-model="permBusinesses"
                    :disabled="admin"
                    :value="option.value"
                    :class="option.class"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              class="mt-0 mt-md-2 float-right"
              :disabled="loading"
              @click.prevent="validationForm"
            >
              <b-spinner
                small
                :hidden="isLoading"
              />
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>Add</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2 mr-2 float-right"
              :disabled="loading"
              @click="cancel()"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Cancel</span>
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BCard,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@/@core/mixins/ui/transition'
import store from '@store';

import axios from 'axios';
import * as api from '@api';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BCard,
    BButton,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      name: '',
      business: null,
      permissions: {},
      emailAddress: '',
      enabled: true,
      loading: false,
      required,
      email,
      isAdmin: false,
      businesses: [{
        value: null, text: 'Select a business', disabled: true,
      }],
      permissionOptions: [
        { text: 'Read', value: 'read', class: 'custom-control-primary' },
        { text: 'Create', value: 'create', class: 'custom-control-success' },
        { text: 'Update', value: 'update', class: 'custom-control-warning' },
        { text: 'Delete', value: 'delete', class: 'custom-control-danger' },
      ],
      permUsers: [],
      permDevices: [],
      permData: [],
      permApiKey: [],
      permBusinesses: [],
    }
  },
  computed: {
    isLoading() {
      return !this.loading;
    },
    admin() {
      return store.state.auth.me.type !== 'super';
    },
  },
  async created() {
    await store.dispatch('getBusinesses');
    store.state.businesses.businesses.forEach(b => {
      this.businesses.push({
        value: b.id,
        text: b.name,
      });
    });
  },
  methods: {
    async cancel() {
      this.$router.go(-1);
    },
    validationForm() {
      this.$refs.userRules.validate().then(success => {
        if (success) {
          this.formSubmitted();
        }
      });
    },
    async formSubmitted() {
      const data = {
        users: { },
        devices: { },
        data: { },
        apiKey: { },
        businesses: { },
      };

      this.permUsers.forEach(element => {
        data.users[element] = true;
      });
      this.permDevices.forEach(element => {
        data.devices[element] = true;
      });
      this.permData.forEach(element => {
        data.data[element] = true;
      });
      this.permApiKey.forEach(element => {
        data.apiKey[element] = true;
      });
      this.permBusinesses.forEach(element => {
        data.businesses[element] = true;
      });

      this.permissions = data;

      const user = {
        name: this.name,
        email: this.emailAddress,
        business: this.business,
        permissions: this.permissions,
        type: this.isAdmin ? 'admin' : 'standard',
        enabled: this.enabled,
      };

      try {
        this.loading = true;

        const result = await axios({
          method: 'post',
          url: api.user,
          data: user,
        });

        if (result.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User Created',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        } else {
          throw new Error();
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      } finally {
        this.$router.push('/users');
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '~@core/assets/fonts/feather/iconfont.css';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>
